body {
  margin: 0; /* Remove default margin */
  background-color: #ffffff; /* Replace with your desired color */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Push content to the top and bottom */
  height: 100vh; /* Full viewport height */
  text-align: left; /* Align text to the left */
  padding: 2rem;
  max-width: 500px; /* Adjust based on your design */
  box-sizing: border-box;
  margin: auto;
  font-family: 'Arial', sans-serif;
 
}

.button-container {
  position: absolute;
  left: 50%;
  bottom: 20px; /* Adjust the distance from the bottom */
  transform: translateX(-50%); /* Center the button container */
  width: 100%; /* Full width to ensure centering works */

}


.header {
  margin-top: 30%; /* Space from the top */
  flex-grow: 1;
}

.footer {
  display: flex; /* Enables Flexbox */
  justify-content: center; /* Centers children horizontally in the footer */
  align-items: center; /* Centers children vertically in the footer */
  margin-top: auto; /* Pushes the footer to the bottom of the container */
  width: 100%; /* Footer takes full width of its parent container */
  max-width: 500px; /* Maximum width of the footer */
  align-self: center; /* Center the footer in case it's inside another flex container */
}



.hero-image {
  display: block; /* Makes the image a block element */
  margin: 0 auto; /* Centers the image */
  margin-top: 15%; /* Space from the top */
  max-width: 300px; /* Adjust the size as needed */
  height: auto; /* Keeps the aspect ratio of the image */
}

.title {
  font-size: 2em; /* Large text for the title */
  font-weight: bold;
  margin: 1rem 0; /* Consistent margin on the top and bottom */
  color: #000; /* Black text */
}

.subtext {
  font-size: 1em;
  margin: 0.5rem 0 2rem 0; /* Consistent margin */
  color: #333; /* Dark grey text for better readability */
}

.goButton {
  background-color: #000000; /* Black background */
  color: #fff; /* White text */
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1em;
  border-radius: 15px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Button width as 80% of its container */
  max-width: 500px; /* Maximum width of 500px */
  align-self: center; /* Centers the button in the footer */
  margin: 20px auto; /* Vertical margin and auto horizontal margin for centering */
}

/* Style the form */
form {
  max-width: 400px; /* Adjust the width as needed */
  margin: auto; /* Center the form on the page */
  padding: 20px; /* Add some padding inside the form */
  border-radius: 10px; /* Rounded corners for the form */
}

/* Style the input fields */
input[type="text"],
input[type="email"],
input[type="password"] {
  width: calc(100% - 24px); /* Full width minus padding */
  margin-bottom: 15px; /* Space between the fields */
  padding: 10px; /* Padding inside the fields */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 5px; /* Slightly rounded corners for the input fields */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Style the submit button */
/* Enhanced style for the submit button */
input[type="submit"] {
  width: 100%; /* Full width */
  padding: 12px 20px; /* Padding inside the button */
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  border-radius: 25px; /* More pronounced rounded corners for the button */
  cursor: pointer; /* Change mouse cursor to pointer when hovering over the button */
  font-size: 18px; /* Increase font size */
  font-weight: bold; /* Make the text bold */
  text-transform: uppercase; /* Uppercase text */
  letter-spacing: 1px; /* Increase spacing between letters */
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s; /* Smooth transition for background color, shadow, and transform */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Add a hover effect for the button */
input[type="submit"]:hover {
  background-color: #45a049; /* A darker shade of green */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Slightly larger shadow for a "lifted" effect */
  transform: translateY(-2px); /* Move the button up slightly to enhance the "lifted" effect */
}

/* Add a focus effect for the button to improve accessibility */
input[type="submit"]:focus {
  outline: none; /* Remove the default focus outline */
  border: 2px solid #fff; /* Add a white border for a focus state */
  box-shadow: 0 0 0 2px #4CAF50; /* Expand the existing shadow to include the focus state */
}